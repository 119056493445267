<template>
  <div id="online_courses_mycourses" class="online_courses_public">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <div class=" title_wrap">
      <div class="w1100 container">
        <div class="title">
          <h3 class="txt-bold">我的課程</h3>
          <img src="@/statics/img/index/icon_title2.svg" alt="">
        </div>
        <form id="courses_filter" @submit.prevent>
          <div class="filter">
            <div class="select_box">
              <small class="select_title txt-bold">課程狀態</small>
              <select
                id="point_type"
                name="point_type"
                class="p"
                v-model="type_id"
                @change="filterData"
              >
                <option value="">全部</option>
                <option value="1">已核給</option>
                <option value="2">已失效</option>
                <option value="3">未完成</option>
              </select>
            </div>
            <div class="input-group">
              <label for="keyword" class="small txt-bold">搜尋課程名稱</label>
              <div class="input-wrap">
                <input
                  id="keyword"
                  name="keyword"
                  type="text"
                  class="p"
                  placeholder="標題"
                  v-model.trim="keyword"
                >
                <button
                  type="button"
                  class="p"
                  @click="filterData"
                >查詢</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="greeting mg-b-50">
      <div class="w1100">
        <div class="user_box">
          <h4 class="txt-dark_green txt-bold">{{ realnameInCookie }}，您好</h4>
          <button
            type="button"
            class="btn p"
            @click="logoutRequest"
          >使用者登出</button>
        </div>
      </div>
    </div>

    <div class="course_list w1100">

      <table class="table" style="width:100%;min-width:1000px">
        <thead>
          <tr class="small">
            <th class="cut15">{{ tableHead1 }}</th>
            <th class="cut15">{{ tableHead2 }}</th>
            <th class="txt-left">{{ tableHead3 }}</th>
            <th class="cut10">{{ tableHead4 }}</th>
            <th class="cut15">{{ tableHead5 }}</th>
            <th class="cut10">{{ tableHead6 }}</th>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="row in computedResultKeyword" :key="row.uuid"> -->
          <tr v-for="(row, index) in resultArray" :key="index">
            <td :data-th="tableHead1">
              <small class="txt-red">
                <template v-if="row.expiry_time">
                  {{ row.expiry_time.split(' ')[0] }}
                  <br>
                  {{ row.expiry_time.split(' ')[1] }}
                </template>
                <template v-else>
                  無限制
                </template>
              </small>
            </td>
            <td :data-th="tableHead2">
              <!-- <small>{{ row.create_date }}<br>
              {{ createdTimeZone(row.create_time) }}</small> -->
              <small>{{ row.create_date }}<br>{{ row.create_time }}</small>
            </td>
            <td :data-th="tableHead3" class="td-main">
              <router-link :to="`/register/online/mycourse/info?course_id=${row.uuid}`">
                {{ row.course_name }}
              </router-link>
            </td>
            <td :data-th="tableHead4">
              <p>
                <span class="txt-light_green">{{ row.video_pass_count }}</span>
                <span class="txt-dark_green">/{{ row.total }}</span>
              </p>
            </td>
            <td :data-th="tableHead5">
              <p class="small" :class="`type-${row.fraction}`">
                <!-- <template v-if="row.fraction_name && row.fraction"> -->
                <template v-if="level * 1 === 1 && row.fraction">
                  <span class="tag">{{ row.fraction }}類</span>
                  <span class="credit">．{{ row.fraction_num }}學分</span>
                  <br><br>
                </template>
                {{ row.course_hours }}小時
              </p>
            </td>
            <td :data-th="tableHead6"><p>
              {{ courseStatus(row.completed, row.expiry_time) }}
            </p></td>
          </tr>
        </tbody>
      </table>
    </div>

    <Pagination
      :pageButton="pageButton"
      :page="page"
      :totalPage="totalPage"
      @updatePage="updatePage"
    />
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterOnlineSubHeader } from '@/lib/const';
import Pagination from '@/components/Pagination.vue';
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import { setAxiosHeaders } from '@/boot/axios';
import { myCourses } from '@/lib/http';
import { expiryDate } from '@/lib/public';
import moment from 'moment';

export default {
  name: 'RegisterOnlineMycourse',
  data() {
    return {
      /** Static Data */
      ...RegisterOnlineSubHeader,
      tableHead1: '觀看期限',
      tableHead2: '購買日期',
      tableHead3: '課程名稱',
      tableHead4: '單元進度',
      tableHead5: '積分/時數',
      tableHead6: '課程狀態',
      pageButton: 5,
      placeHolder: '',

      /** Search Params */
      type_id: '',
      keyword: '',
      limit: 10,
      page: 1,

      /** Axios Data */
      totalPage: 1,
      resultArray: [
        // {
        //   id: 1,
        //   view: {
        //     date: '2020/04/01',
        //     time: '08:00',
        //   },
        //   purchase: {
        //     date: '2020/03/01',
        //     time: '08:00',
        //   },
        //   title: '乙類安寧居家療護 - 108年度社區安寧照護(乙類)醫護人員教育訓練',
        //   unit: {
        //     progress: '10',
        //     whole: '10',
        //   },
        //   credit: {
        //     hour: '6小時',
        //     tag: '',
        //     point: '',
        //   },
        //   status: '已核給',
        // },
        // {
        //   id: 2,
        //   view: {
        //     date: '2020/04/01',
        //     time: '08:00',
        //   },
        //   purchase: {
        //     date: '2020/03/01',
        //     time: '08:00',
        //   },
        //   title: '安寧療護與病主法推展與困境',
        //   unit: {
        //     progress: '10',
        //     whole: '10',
        //   },
        //   credit: {
        //     hour: '',
        //     tag: 'A',
        //     point: '3',
        //   },
        //   status: '已核給',
        // },
        // {
        //   id: 3,
        //   view: {
        //     date: '2020/04/01',
        //     time: '08:00',
        //   },
        //   purchase: {
        //     date: '2020/03/01',
        //     time: '08:00',
        //   },
        //   title: '帶狀皰疹疾病負擔與疫苗介紹',
        //   unit: {
        //     progress: '10',
        //     whole: '10',
        //   },
        //   credit: {
        //     hour: '',
        //     tag: 'B',
        //     point: '3',
        //   },
        //   status: '已失效',
        // },
      ],
      user: {
        name: '王小明',
      },
    };
  },
  created() {
    if (this.hasToken) {
      this.filterData();
    } else {
      this.assignUrlRedirect('/login');
      this.updateModalInfoContent('效期逾時，請重新登入');
      this.toggleModalInfo(true);
    }
  },
  computed: {
    ...mapState([
      'level',
    ]),
    ...mapGetters([
      'hasToken',
    ]),
    realnameInCookie() {
      return this.$cookies.get('realname');
    },
    // computedResultStatus() {
    //   if (this.pointCategory === '未完成') {
    //     return this.resultArray.filter((item) => item.completed === false);
    //   }
    //   if (this.pointCategory === '已失效') {
    //     return this.resultArray.filter((item) => item.completed === '已失效');
    //   }
    //   if (this.pointCategory === '已核給') {
    //     return this.resultArray.filter((item) => item.completed === true);
    //   }
    //   return this.resultArray;
    // },
    // computedResultKeyword() {
    //   if (this.keyword) {
    //     return this.computedResultStatus
    //       .filter((item) => item.course_name.indexOf(this.keyword) !== -1);
    //   }
    //   return this.computedResultStatus;
    // },
  },
  methods: {
    ...mapActions([
      'logout',
    ]),
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'assignUrlRedirect',
    ]),
    filterData() {
      myCourses({
        type_id: this.type_id,
        keyword: this.keyword,
        limit: this.limit,
        page: this.page,
      }).then((result) => {
        if (Object.keys(result).length !== 0) {
          if (result.data) {
            this.resultArray = result.data;
          }
          this.totalPage = result.pageCount;
          // console.log('result', this.resultArray);
        } else {
          this.assignUrlRedirect('/login');
          this.updateModalInfoContent('效期逾時，請重新登入');
          this.toggleModalInfo(true);
        }
      });
    },
    updatePage(value) {
      this.page = value;
      this.$router.push({ query: { num: value } });
    },
    logoutRequest() {
      this.logout()
        .then(() => {
          this.$router.push({ path: '/' });
          setAxiosHeaders(null);
        });
    },
    createdTimeZone(str) {
      const arr = str.split(':');
      // const h = arr[0].split('');
      arr[0] = arr[0] * 1 + 8;
      return arr.join(':');
    },
    courseStatus(status, expire) {
      let text = '';
      if (expire) {
        if (status === true) {
          text = '已核給';
        } else {
          // eslint-disable-next-line no-lonely-if
          if (moment().isAfter(expire)) {
            text = '已失效';
          } else {
            text = '未完成';
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (status === true) {
          text = '已核給';
        } else {
          text = '未完成';
        }
      }
      return text;
    },
    updateKeyword() {
      this.keyword = this.placeHolder;
    },
    expiryDate,
  },
  watch: {
    page() {
      this.filterData();
    },
  },
  components: {
    PageSubHeader,
    Pagination,
  },
};
</script>
